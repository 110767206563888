.lightbox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $black-transparent;
  text-align: center;
  z-index: 5;

  img {
    @include vertical-align;
    width: auto;
    max-width: 100vw;
    height: auto;
    max-height: 100vh;
    margin: 0 auto;
    cursor: zoom-out;
  }
}

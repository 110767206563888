h1 {
  color: $white;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
}

h2 {
  color: $grey-dark;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
}

@media only screen and (min-width: 480px) {
  h2 {
    font-size: 35px;
  }
}

p {
  margin: 0;
  padding: 0 0 25px;
  text-align: justify;

  &:last-child {
    padding: 0;
  }
}

.header {
  height: 100vh;
  //background-attachment: fixed;
  background-image: url('../img/header.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;

  &__inner {
    @include vertical-align;

    h1 {
      margin: 0 0 10px;
    }

    a {
      display: block;
      transform: translateY(0);
      color: $white;

      &:active,
      &:focus,
      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}

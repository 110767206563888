.list {
  margin: 0;
  padding: 0;

  &--inline {
    list-style-type: none;

    li {
      display: inline-block;
      padding: 0 10px;
    }
  }
}

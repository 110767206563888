.container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 50px 25px;
}

.intro {
  padding: 0 0 25px;

  p {

    &::first-letter {
      font-weight: 700;
    }
  }
}

@media only screen and (min-width: 480px) {
  .intro {
    padding: 0 0 50px;
  }
}

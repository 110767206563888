.project {
  margin-bottom: 50px;

  h2 {
    margin: 0;
  }

  .meta {
    padding-bottom: 10px;

    span {
      display: block;
    }

    &__date {
      display: block;
      color: $grey-light;
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
@media only screen and (min-width: 480px) {
  .project {
    margin-bottom: 50px;

    .meta {
      margin-bottom: 5px;
    }
  }
}

.footer {
  padding: 25px 0;
  background-color: $black;
  text-align: center;

  a {
    display: block;
    transform: translateY(0);
    color: $white;

    &:active,
    &:focus,
    &:hover {
      transform: translateY(-2px);
    }
  }
}

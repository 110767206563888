.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;
  grid-gap: 5px;
  grid-auto-flow: dense;

  &__item {
    @include transition(all, 1s);
    opacity: 1;

    &:active,
    &:focus,
    &:hover {
      transform: scale(.95);
      opacity: .75;
    }

    img {
      width: 100%;
      height: 100%;
      cursor: zoom-in;
      object-fit: cover;
    }

    &--wide {
      grid-column: span 2;
    }

    &--tall {
      grid-row: span 2;
    }

    &--square {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
}

@media only screen and (min-width: 480px) {
  .gallery {
    grid-auto-rows: 200px;
    grid-gap: 10px;
  }
}

.hidden {
  transform: translateY(50px);
  opacity: 0;
}

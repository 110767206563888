*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: $white;
  color: $black;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

a {
  @include transition(all .5s);
  color: $black;
}
